/**
 * Layer Reveal Solo JS (class based approach)
 * 
 * Einzelne Layer, die über einen Button ausgeklappt werden können
 *
 * Data-Attributes, that can be passed from HTML:
 * 
 * data-default:
 * true = already open
 * 
 * data-duration:
 * "adaptive" = the css transition duration gets recalculated dependent to the content height
 * "constant" = no matter the height of the content the same duration is used
 */
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
// One or more div layers
const layers = document.querySelectorAll( '.layer-reveal-solo' )
// Array of Instances
const refs = []
/*-------------------------------------------------------------------------------
# CLASS
-------------------------------------------------------------------------------*/
class LayerRevealSolo {

    constructor( lrs, id ) {
        this.lay = lrs
        this.id = id

        this.open = this.lay.querySelector('.layer-reveal-solo_open')
        this.content = this.lay.querySelector('.layer-reveal-solo_content')
        this.close = this.lay.querySelector('.layer-reveal-solo_close')

        this.state = false      // the state (open or closed)
        this.h                  // computed height of inner container
        this.def = false        // the defaultState (open or closed)
        this.dur = 'adaptive'
        this.speedPx = 200      // the pixel height the css base speed corresponds to
        this.baseSpeed = .6    // 

        this.init()
    }

    init() {
        // check for data-attributes
        this.checkAttr();
        // if an open "button" and a content layer exists continue...
        if (this.open && this.content) {
            
            this.computeHeight()
            this.setSpeed()
            
            if (this.def) {
                this.state = true
                this.show()
            }

            this.listeners()
        }
    }

    computeHeight() {
        // get margins and borders of content child element (innerDiv) and add to offsetHeight
        // getComputedStyle doesn’t work on IE 8 and below
        let tH = this.content.children[0].offsetHeight
        const tStyle = window.getComputedStyle(this.content.children[0])
        tH += parseInt(tStyle.marginTop, 10)
        tH += parseInt(tStyle.marginBottom, 10)
        tH += parseInt(tStyle.paddingTop, 10)
        tH += parseInt(tStyle.paddingBottom, 10)
        tH += parseInt(tStyle.borderTopWidth, 10)
        tH += parseInt(tStyle.borderBottomWidth, 10)
        
        // the heights (derived from the child height, <p> or inner <div>)
        this.h = tH
    }

    setSpeed() {
        // get the original transition speed for the content from CSS (or use default)
        const tBS = window.getComputedStyle(this.content).transitionDuration
        if (tBS) this.baseSpeed = parseFloat(tBS)
        // set the speed in relation to height
        // the baseSpeed accounts for a height of 200 px
        if ( this.dur === "adaptive" ) {
            let tSpeed = (this.h / this.speedPx) * this.baseSpeed 
            this.content.style.transitionDuration = tSpeed + 's'
        }
    }

    listeners() {
        this.open.onclick = (e) => {
            e.preventDefault()
            if ( !this.state ) {
                this.state = !this.state
                this.show()
            }
        }
        if (this.close) {
            this.close.onclick = (e) => {
                e.preventDefault()
                if (this.state) {
                    this.state = !this.state
                    this.hide()
                }
            }
        }
    }

    show() {
        this.content.style.height = this.h + 'px'
        this.content.classList.add('is-visible')
        //this.open.classList.add('is-active')
        this.open.classList.add('fade-out')
    }

    hide() {
        this.content.style.height = 0
        this.content.classList.remove('is-visible')
        //this.open.classList.remove('is-active')
        this.open.classList.remove('fade-out')
    }

    checkAttr() {
        if ( this.lay.hasAttribute('data-default') ) {
            const tDef = this.lay.getAttribute('data-default')
            if( tDef === "true") {
                this.def = true
            }
        }
        if ( this.lay.hasAttribute('data-duration') ) {
            const tDur = this.lay.getAttribute('data-duration')
            if( tDur === "adaptive" || tDur === "constant" ){
                this.dur = tDur;
            }
        }
    }

    update() {
        this.computeHeight()
        this.setSpeed()
        // if already open adjust height
        if (this.state) {
            this.content.style.height = this.h + 'px'
        }
    }
}

/*-------------------------------------------------------------------------------
# Create Layer Reveal
-------------------------------------------------------------------------------*/
function createLayers() {

    if ( layers ) {
        layers.forEach( ( el, index ) => {
            const tRef = new LayerRevealSolo( el, index );
            refs.push(tRef)
        });
    }
}

/*-------------------------------------------------------------------------------
# Window Load
-------------------------------------------------------------------------------*/
window.addEventListener ?
window.addEventListener( 'load', createLayers, false ) : 
window.attachEvent && window.attachEvent( 'onload', createLayers );

/*-------------------------------------------------------------------------------
# Resize
-------------------------------------------------------------------------------*/
let resizeID
window.addEventListener('resize', () => {
    clearTimeout(resizeID)
    resizeID = setTimeout(() => {
        refs.forEach((el) => {
            el.update()
        })
    }, 500)
})