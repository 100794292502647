/**
 * Layer Reveal JS
 * 
 * Abgeleitet von Accordion JS, aber als eine einzelne oder eine Gruppe von Layern, die über einen Link (z.B. eine Headline iwe bei Karriere und einen zusätzlichen Button) ausgeklappt werden können, der Skript ist eigentlich exakt gleich (nur für das Schließen kommen 2 andere Buttons in Frage)
 *
 * Data-Attributes, that can be passed from HTML:
 * 
 * data-mode:
 * "single" = only one element can be open (a click on a new element closes the old element)
 * "multiple" = many items can be open at the same time (only a click on the same element opens and closes it)
 * 
 * data-default:
 * "false" = no element is open by default, adding data-open="true" on an element has no effect
 * "true" = if ".accordion_content" elements have a data-open="true" attribute they will be initially open, combined
 * with data-mode: "single" on the accordion parent this will only be recognized for the first occurence
 * 
 * data-duration:
 * "adaptive" = the css transition duration gets recalculated dependent to the content height
 * "constant" = no matter the height of the content the same duration is used
 * 
 * data-btn-repl-txt:
 * "String" = if non-empty the button text will be replaced by this string (same for all buttons in the layer reveal group)
 * 
 * IMPROVEMENTS:
 * 1. Each layer gets its own replacement text for the button text (original text can already be different)
 * 2. Animate the text swap of the button (fade old text out with opening of layer and fade new text in when layer is finished opening?) how about a extra class?
 */
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
const layers = document.querySelector( '#layer-reveal-group' )
const openers = layers ? layers.querySelectorAll('.layer-reveal_opener') : undefined // the clickable opener buttons
const openers2 = layers ? layers.querySelectorAll('.layer-reveal_opener_2') : undefined // the clickable opener links (i.e. headlines)
const contents = layers ? layers.querySelectorAll('.layer-reveal_content') : undefined // the foldable content layers (simple array)

const contentStates = [] // extra array for content 'open states' (Array of Booleans)
const contentHeights = [] // extra array for content heights (Array of Numbers)
const ogTxts = [] // extra array for original buttons texts (Array of Strings) NOTE: They should be the same for all layers and currently the replaceText is a data-attribute of the group (not each single layer), therefore the replaceText will always be the same...
let dataMode = "single" // the default
let defaultFlag = false // the default
let duration = "adaptive" // the default
const baseSpeedRel = 200 // the pixel height the css base speed corresponds to
let replTxt = ""

/*-------------------------------------------------------------------------------
# Setup
-------------------------------------------------------------------------------*/
// we have to wait until the document is completely loaded (all styles) otherwise the magic-line doesm’t have the correct size
function setupLayerReveal() {

    if(layers){
        // Check for data-attributes
        checkAttributes();
        // loop opener buttons & contents
        if (openers.length > 0){
            
            // Read out the original transition speed for the content
            // only needs to be done once (as this css value is the same for all content elements)
            const baseSpeed = parseFloat(window.getComputedStyle(contents[0]).transitionDuration)

            openers.forEach((opener, index) => {
                // get margins and borders of content child element and add to offsetHeight
                // getComputedStyle doesn’t work on IE 8 and below
                let tHeight = contents[index].children[0].offsetHeight
                const tCompStyle = window.getComputedStyle(contents[index].children[0])
                tHeight += parseInt(tCompStyle.marginTop, 10)
                tHeight += parseInt(tCompStyle.marginBottom, 10)
                tHeight += parseInt(tCompStyle.borderTopWidth, 10)
                tHeight += parseInt(tCompStyle.borderBottomWidth, 10)
                
                // the heights (derived from the child height, <p> or inner <div>)
                contentHeights[index] = tHeight
            
                // set the speed in relation to height
                // the baseSpeed accounts for a height of 200 px
                if ( duration === "adaptive" ) {
                    let tSpeed = (tHeight / baseSpeedRel) * baseSpeed 
                    contents[index].style.transitionDuration = tSpeed + 's'
                }
                
                // set the intitial state for all accordion elements
                let tState = false

                if(defaultFlag == true){
                    // only if there can be default open elements we check for attribute
                    if ( contents[index].hasAttribute('data-open') ) {
                        const tMode = contents[index].getAttribute('data-open')
                        if ( tMode === "true" ) {
                            showContent(index)
                            tState = true
                            defaultFlag = false
                        }
                    }
                }
                contentStates[index] = tState

                // get the original texts (innerText for IE <9 and textContent for everything else)
                const tTxt = (opener.innerText || opener.textContent)
                ogTxts[index] = tTxt

                opener.onclick = (e) => {
                    
                    e.preventDefault()
                    let stateOnClick = contentStates[index]

                    if ( !stateOnClick ) {
                        // open the item
                        showContent(index)
                        //
                        changeTxt(index, true)
                        // close the others
                        if (dataMode === 'single' ) {
                            for (let i = 0; i < contentStates.length; i++ ){
                                if ( i == index || contentStates[i] == false ){
                                    // it's either the clicked element or the state is false (not open)
                                    continue
                                } else {
                                    // the state is true and it’s not the clicked element
                                    hideContent(i)
                                    changeTxt(i, false)
                                    contentStates[i] = false
                                }
                            }
                        }
                    } else {
                        // close the item
                        hideContent(index)
                        changeTxt(index, false)
                    }
                    stateOnClick = !stateOnClick
                    contentStates[index] = stateOnClick
                }
            })
            // Additional Headline Clicks
            /**
             * Only if additional links exist and only if their number matches the number of buttons
             * Simply call the corresponding onclick function of the button
             * NOTE: It would be more complicated to check which link belongs to which button in a mixed situation (where some reveals have an extra link opener and some haven’t)
             */
            if (openers2 && openers2.length == openers.length) {
                openers2.forEach( (opener2, index2) => {
                    opener2.onclick = (e) => {
                        e.preventDefault()
                        openers[index2].click()
                    }
                })
            }
         }
    }
}
window.addEventListener ? 
window.addEventListener("load",setupLayerReveal, false) 
: 
window.attachEvent && window.attachEvent("onload",setupLayerReveal)
/*-------------------------------------------------------------------------------
# Show Content
-------------------------------------------------------------------------------*/
function showContent(index) {
    contents[index].style.height = contentHeights[index] + 'px'
    contents[index].classList.add('is-visible')
    openers[index].classList.add('is-active')
}
/*-------------------------------------------------------------------------------
# Hide Content
-------------------------------------------------------------------------------*/
function hideContent(index) {
    contents[index].style.height = 0
    contents[index].classList.remove('is-visible')
    openers[index].classList.remove('is-active')
}
/*-------------------------------------------------------------------------------
# Change Button Text
-------------------------------------------------------------------------------*/
function changeTxt(i, f) {
    const tOp = openers[i]
    // true = change to replacement text (open)
    if ( f ) {
        if (replTxt !== '') {
            if ( tOp.textContent ) {
                tOp.textContent = replTxt
            } else if ( tOp.innerText ) {
                tOp.innerText = replTxt
            }
        }
    // false = change back to original text
    } else {
        if ( tOp.textContent ) {
            tOp.textContent = ogTxts[i]
       } else if ( tOp.innerText ) {
            tOp.innerText = ogTxts[i]
       }
    }
    
}
/*-------------------------------------------------------------------------------
# CheckAttributes
-------------------------------------------------------------------------------*/
function checkAttributes() {
    if ( layers.hasAttribute('data-mode') ) {
        const tMode = layers.getAttribute('data-mode')
        if( tMode === "single" || tMode === "multiple" ){
            dataMode = tMode;
        }
    }
    if ( layers.hasAttribute('data-default') ) {
        const tDefault = layers.getAttribute('data-default')
        if( tDefault === "false") {
            defaultFlag = false;
        } else if ( tDefault === "true" ){
            defaultFlag = true;
        }
    }
    if ( layers.hasAttribute('data-duration') ) {
        const tDuration = layers.getAttribute('data-duration')
        if( tDuration === "adaptive" || tDuration === "constant" ){
            duration = tDuration;
        }
    }
    if ( layers.hasAttribute('data-btn-repl-txt') ) {
        const tReplTxt = layers.getAttribute('data-btn-repl-txt')
        if ( tReplTxt !== '' ) {
            replTxt = tReplTxt
        }
    }
}