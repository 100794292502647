/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
const ga_code = ''
const topbar = document.querySelector('.topbar')
let ssClosedBar
//let cookie
let resizeID
let trackState = false
// Optout checkbox in Google Analytics section on "Datenschutz" page
const optout = document.querySelector('#cookie_status')
//let started = false
/*-------------------------------------------------------------------------------
# 1. – Check Session Storage (on each page load)
-------------------------------------------------------------------------------*/
function checkSession() {

    // at first we check for our session Storage item "cb" (Cookiebar)
    const ss = sessionStorage.getItem( 'cb' )
    // if storage key is empty
    if ( null === ss ) {
        // only if sessionStorage is empty we check for a cookie element
        checkCookie()
    } else if ( 'ok' === ss ){
        // if we set to ok we can directly trigger tracking
        track()
    }
    // if key returns "hide" we simply do nothing (keep topbar hidden but do not track!)
}
checkSession()
/*-------------------------------------------------------------------------------
# Check Cookie
-------------------------------------------------------------------------------*/
function checkCookie() {
 
    // Check for the "accept_ga" (Accept Google Analytics) Cookie
    const acceptCookies = getCookie("accept_ga");
    
    if (acceptCookies == "") {
        // no cookie set
        showBar()
    } else {
        // cookie set
        track()
    }
}
/*-------------------------------------------------------------------------------
# Set Cookie
-------------------------------------------------------------------------------*/
function setCookie(cname, cvalue, exdays) {
    // überprüfen, ob die expiry auf 0 steht oder leer ist (= sessioncookie)
    // evtl. wird nicht in jedem Browser auf jedem Endgeraet der Cookie beim Beenden des Browsers gelöscht...
    let expires = ''
    if ( exdays != 0 || exdays != '' ) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        expires = ";expires="+ d.toUTCString();
    }
    document.cookie = cname + "=" + cvalue + expires + ";samesite=lax" + ";path=/";
}
/*-------------------------------------------------------------------------------
# Get Cookie
-------------------------------------------------------------------------------*/
function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for ( let i = 0; i < ca.length; i++ ) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
/*-------------------------------------------------------------------------------
# Set Optout (the optout checkbox on DSGVO page – on pageload)
-------------------------------------------------------------------------------*/
function setOptout() {
    if (optout) {
         optout.checked = trackState
         optout.addEventListener('click', function() {
             getOptout()
         })
    }
}
setOptout()

/*-------------------------------------------------------------------------------
# Get Optout (on click)
-------------------------------------------------------------------------------*/
function getOptout() {
    // Disable and allow tracking from optout checkbox
    trackState = !trackState
    if (optout.checked === false) {
        // Disable
        setCookie('accept_ga','',-1);
        sessionStorage.setItem( 'cb', 'hide' );
        // let’s for now not close the bar from here
    } else {
        // Allow
        setCookie('accept_ga','yes',1825);
        sessionStorage.setItem( 'cb', 'ok' );
        // let’s for now not close the bar from here
    }
}
/*-------------------------------------------------------------------------------
# Topbar Add Click Listeners
-------------------------------------------------------------------------------*/
function topBarClicks() {

    if (topbar) {
        
        setHeight()

        const confirm = topbar.querySelector('#topbar_actions_confirm')
        if (confirm) {
            confirm.addEventListener('click', function(e) {
                e.preventDefault()
                confirmClick()
            })
        }

        const close = topbar.querySelector('.topbar_close')
        if (close) {
            close.addEventListener('click',closeClick)
        }
    }
}
topBarClicks()
/*-------------------------------------------------------------------------------
# Set Height (of bar)
-------------------------------------------------------------------------------*/
function setHeight() {
    topbar.style.height = topbar.offsetHeight + "px"
}
/*-------------------------------------------------------------------------------
# Click on Confirm ("OK")
-------------------------------------------------------------------------------*/
function confirmClick() {
    // here we could trigger custom DSGVO actions (set a cookie that accept cookies has been confirmed)
    setCookie('accept_ga','yes',1825);
    //
    track()
    // also set a temporary session storage to prevent the cookiebar from showing again while the session is active, but set to "ok" so we don't have to also check for cookie on each pageload
    sessionStorage.setItem( 'cb', 'ok' );
    // hide the cookie notice
    closeBar()
}
/*-------------------------------------------------------------------------------
# Click on Close ("X")
-------------------------------------------------------------------------------*/
function closeClick() {
    // set a temporary session storage to prevent the cookiebar from showing again while the session is active
    sessionStorage.setItem( 'cb', 'hide' );
    // hide the cookie notice
    closeBar()
}

/*-------------------------------------------------------------------------------
# Show bar
-------------------------------------------------------------------------------*/
function showBar() {
    if (topbar) {
        //topbar.style.height = 0
        topbar.classList.remove('topbar_hidden')
    }
}

/*-------------------------------------------------------------------------------
# Close bar
-------------------------------------------------------------------------------*/
function closeBar() {
    if (topbar) {
        topbar.style.height = 0
        topbar.classList.add('topbar_hidden')
    }
}

/*-------------------------------------------------------------------------------
# Track
-------------------------------------------------------------------------------*/
function track() {
    //
    trackState = true;
    
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        
        ga('create', 'UA-181120611-1', 'auto');
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');
}

/*-------------------------------------------------------------------------------
# Close the bar
-------------------------------------------------------------------------------*/

    
window.addEventListener('resize', () => {

    if ( topbar && topbar.style.height != '0px' ) {
        // would be enough to set this once resizing begins...
        topbar.style.height = 'auto'
        // this only fires when resizing finishes
        clearTimeout(resizeID)
        resizeID = setTimeout(setHeight, 500)
    }
})
