/*-------------------------------------------------------------------------------
# Scroll Emitter
-------------------------------------------------------------------------------*/
/**
 * The Scroll Emitter class is an ES6 module that adds a throttled scroll listener
 * Other Components can import ScrollEmitter and hook a function via the "on" method
 */

class ScrollEmitter {

    // ··········································································
    // constructor
    constructor() {
        // Array of functions
        this._fns = []
        // Add Scroll Listener
        window.addEventListener( 'scroll', this.debounce( this, this.emitt ) );
    }
    
    // ··········································································
    // add permanent
    
    on(fn) {
        // perform check for function
        const check = this.isFunction(fn)
        // if check returns true add to array
        if(check) this._fns.push(fn)
    }
    
    // ··········································································
    // add once
    
    once(fn) {
        // HOW COULD THIS BE ACCOMPLISHED ?
        // add only for one (the next) emitt call
        // perform check for function
        const check = this.isFunction(fn)
    }
    
    // ··········································································
    // remove permanent
    
    off(fn) {
        // perform check for function
        const check = this.isFunction(fn)
        // find function in array
        if ( check ) {
            const t_Arr = this._fns.filter(el => el !== fn)
            this._fns = [...t_Arr]
        }
    }
    // ··········································································
    // loop through array and call functions

    emitt() {
        this._fns.forEach( el => {
            el()
        });
    }

    // ··········································································
    // checks whether the passed reference is a function
    
    isFunction(el) {
        return !!(el && el.constructor && el.call && el.apply)
    }

    // ··········································································
    // Debounce function 
    
    // immediate = false funcktioniert nicht so wie gedacht
    debounce( obj ,func, wait = 10, immediate = true ) {
        let timeout;
        return function() {
            let ctx = this,
                args = arguments;
            let later = function() {
                timeout = null;
                if ( ! immediate ) {
                    //func.apply( ctx, args );
                    
                    func.apply(obj, args)
                }
            };
            let callNow = immediate && ! timeout;
            clearTimeout( timeout );
            timeout = setTimeout( later, wait );
            if ( callNow ) {
                //func.apply ( ctx, args );
                func.apply(obj, args)
            }
        }
    }

}
// Export an instance of Scroll Emitter
export default new ScrollEmitter()