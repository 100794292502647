/* import the json data for the german – english page references and the corresponding id
the JSON structure is an array of objects, with the keys:
- "id" (number – the page id),
- "de" (string – name of german html page, without suffix),
- "en" (string – name of english html page, without suffix)
*/
import data from '../data/pages.json'
// the language switch component in the header
const langSwitchHead = document.querySelector( '.menu-item-ls' )
const langSwitchFooter = document.querySelector( '#footer_lang_select' )
const suffix = ".html"
const deLangCode = "de"
const enLangCode = "en"

/**
 * - Gets the current page id and language attribute
 * - Reads the corresponding page name from the imported JSON data
 * - calls the translated page
 */
const switchLanguage = () => {
    // the <html> element
    const el = document.documentElement
    // get the page id attribute (data-pageid) from the <html> element and convert to integer
    const pageId = parseInt(el.dataset.pageid)
    // get the language-attribute from the <html> element
    const currLang = el.lang
    // find the index of the pageId (should be the same, but not necessarily)
    const index = data.findIndex(entry => entry.hasOwnProperty('id') && entry['id'] === pageId)
    // name of new page
    let newPage = ""
    // savity check
    if ( currLang && index != -1 ){
        // check if we are on an english page or german
        if ( currLang === deLangCode ){
            // get the english equivalent to the currect german page
            newPage = data[index]['en']
        } else if ( currLang === enLangCode ){
            // get the german equivalent to the currect english page
            newPage = data[index]['de']
        }
        // call the new page
        if ( newPage != "" ){
            window.location.href = `./${newPage}${suffix}`
        }
    }
}
/**
 * Click listener for langugage switch in header
 */
langSwitchHead && langSwitchHead.addEventListener( 'click', function( e ) {
    e.preventDefault();
    switchLanguage();
});
/**
 * Click listener for langugage switch in footer
 */
langSwitchFooter && langSwitchFooter.addEventListener( 'click', function( e ) {
    e.preventDefault();
    switchLanguage();
});

