/**
 * Accordion JS
 *
 * Data-Attributes, that can be passed from HTML:
 * 
 * data-mode: 
 * "single" = only one element can be open (a click on a new element closes the old element)
 * "multiple" = many items can be open at the same time (only a click on the same element opens and closes it)
 * 
 * data-default:
 * "false" = no element is open by default, adding data-open="true" on an element has no effect
 * "true" = if ".accordion_content" elements have a data-open="true" attribute they will be initially open, combined
 * with data-mode: "single" on the accordion parent this will only be recognized for the first occurence
 * 
 * data-duration:
 * "adaptive" = the css transition duration gets recalculated dependent to the content height
 * "constant" = no matter the height of the content the same duration is used
 * 
 * data-use-hash-id:
 * "true" = the accordion will check the location.href for a hash, if this is a number within the length of the accordion items array this item will be opened by default (overriding any data-open settings on items) 
 */
/*-------------------------------------------------------------------------------
# Imports
-------------------------------------------------------------------------------*/
import getStyleName from './prefix'
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
const accordion = document.querySelector( '#accordion-1' )
const titles = accordion ? accordion.querySelectorAll('.accordion_title') : undefined // the clickable titles
const contents = accordion ? accordion.querySelectorAll('.accordion_content') : undefined // the foldable content layers (simple array)

const contentStates = [] // extra array for content 'open states' (Array of Booleans)
const contentHeights = [] // extra array for content heights (Array of Numbers)
let dataMode = "single" // the default
let defaultFlag = false // the default
let duration = "adaptive" // the default
let dataUseHash = false // the default
let hashId
const baseSpeedRel = 200 // the pixel height the css base speed corresponds to
// CSS Values that probably need prefixing
const transitionDuration = getStyleName('transitionDuration')

/*-------------------------------------------------------------------------------
# Burger Menu
-------------------------------------------------------------------------------*/
// we have to wait until the document is completely loaded (all styles) otherwise the magic-line doesm’t have the correct size
function setupAccordion() {

    if(accordion){
        // Check for data-attributes
        checkAttributes();
        // loop titles & contents
        if (titles.length > 0){
            
            // Read out the original transition speed for the content
            // only needs to be done once (as this css value is the same for all content elements)
            const baseSpeed = parseFloat(window.getComputedStyle(contents[0])[transitionDuration])

            titles.forEach((title, index) => {
                // get margins and borders of content child element and add to offsetHeight
                // getComputedStyle doesn’t work on IE 8 and below
                let tHeight = contents[index].children[0].offsetHeight
                const tCompStyle = window.getComputedStyle(contents[index].children[0])
                tHeight += parseInt(tCompStyle.marginTop, 10)
                tHeight += parseInt(tCompStyle.marginBottom, 10)
                tHeight += parseInt(tCompStyle.borderTopWidth, 10)
                tHeight += parseInt(tCompStyle.borderBottomWidth, 10)
                
                // the heights (derived from the child height, <p> or inner <div>)
                contentHeights[index] = tHeight
            
                // set the speed in relation to height
                // the baseSpeed accounts for a height of 200 px
                if ( duration === "adaptive" ) {
                    let tSpeed = (tHeight / baseSpeedRel) * baseSpeed 
                    contents[index].style[transitionDuration] = tSpeed + 's'
                }
                
                // set the intitial state for all accordion elements
                let tState = false

                // Check for default data-open settings and location.hash passed in
                if (hashId == undefined ) {
                    // no hashId – check for default and use it
                    if(defaultFlag == true){
                        // only if there can be default open elements we check for attribute
                        if ( contents[index].hasAttribute('data-open') ) {
                            const tMode = contents[index].getAttribute('data-open')
                            if ( tMode === "true" ) {
                                showContent(index)
                                tState = true
                                defaultFlag = false
                            }
                        }
                    }
                }
                
                contentStates[index] = tState

                title.onclick = (e) => {
                    
                    e.preventDefault()
                    let stateOnClick = contentStates[index]

                    if ( !stateOnClick ) {
                        // open the item
                        showContent(index)
                        // close the others
                        if (dataMode === 'single' ) {
                            for (let i = 0; i < contentStates.length; i++ ){
                                if ( i == index || contentStates[i] == false ){
                                    // it's either the clicked element or the state is false (not open)
                                    continue
                                } else {
                                    // the state is true and it’s not the clicked element
                                    hideContent(i)
                                    contentStates[i] = false
                                }
                            }
                        }
                    } else {
                        // close the item
                        hideContent(index)
                    }
                    stateOnClick = !stateOnClick
                    contentStates[index] = stateOnClick
                }
            })
            // After the loop check for hashId and open element if exists
            if ( hashId != undefined ) {
                contentStates[hashId] = true
                // overwrite defaultFlag
                defaultFlag = false
                //
                showContent(hashId)
            }
         }
    }
}
window.addEventListener ? 
window.addEventListener("load",setupAccordion, false) 
: 
window.attachEvent && window.attachEvent("onload",setupAccordion)
/*-------------------------------------------------------------------------------
# Show Content
-------------------------------------------------------------------------------*/
function showContent(index) {
    contents[index].style.height = contentHeights[index] + 'px'
    contents[index].classList.add('is-visible')
    titles[index].classList.add('is-active')
}
/*-------------------------------------------------------------------------------
# Hide Content
-------------------------------------------------------------------------------*/
function hideContent(index) {
    contents[index].style.height = 0
    contents[index].classList.remove('is-visible')
    titles[index].classList.remove('is-active')
}
/*-------------------------------------------------------------------------------
# CheckAttributes
-------------------------------------------------------------------------------*/
function checkAttributes() {
    if ( accordion.hasAttribute('data-mode') ) {
        const tMode = accordion.getAttribute('data-mode')
        if( tMode === "single" || tMode === "multiple" ){
            dataMode = tMode
        }
    }
    if ( accordion.hasAttribute('data-default') ) {
        const tDefault = accordion.getAttribute('data-default')
        if ( tDefault === "false") {
            defaultFlag = false
        } else if ( tDefault === "true" ){
            defaultFlag = true
        }
    }
    if ( accordion.hasAttribute('data-duration') ) {
        const tDuration = accordion.getAttribute('data-duration')
        if ( tDuration === "adaptive" || tDuration === "constant" ) {
            duration = tDuration
        }
    }
    if ( accordion.hasAttribute('data-use-hash-id') ) {
        const tHashFlag = accordion.getAttribute('data-use-hash-id')
        if ( tHashFlag === "true" ) {
            dataUseHash = true
            checkHash()
        } else {
            dataUseHash = false
        }
    }
}
/*-------------------------------------------------------------------------------
# CheckHash
-------------------------------------------------------------------------------*/
function checkHash() {
    let tHash = location.hash
    let parsedHash = parseInt(tHash.slice(1))
    if ( parsedHash >= 0 && parsedHash < titles.length ) {
        hashId = parsedHash;
    }
    // this might not work in IE9 and below
    // btw: window.location.search keeps Query Parameters
    history.replaceState("", document.title, window.location.pathname + window.location.search)
}