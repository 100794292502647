/**
 * nav-slider.js
 * 
 * Animation of small sliding <hr> line below menu element
 */
const menu = document.getElementsByClassName('main-menu')
const menuSlider = document.getElementById('nav-slider') // the menu slider is an <hr> element    
const padding = 16 // this has to match the left and right padding of the menus <li> or <a> elements
let slideState = false; // boolean to check if the slider is already visible when entering an element

// we have to wait until the document is completely loaded (all styles) otherwise the magic-line doesm’t have the correct size
function setupSlider() {
    
    if (menu) {
        
        if (menuSlider) {
            
            navSlider(function(el,width,tmpLeft){

                el.onmouseover = () => {
                    
                    menuSlider.style.width = width + 'px'
                    menuSlider.style.left = tmpLeft + 'px'

                slideState = true
                }
                el.onmouseout = () => {

                    slideState = false

                    menuSlider.classList.add('sliding')
                    
                    // check slideState after 100 ms
                    setTimeout( mouseOutThrottle , 100 )

                }

            })
        }
    }
}
window.addEventListener ? 
window.addEventListener("load",setupSlider, false) 
: 
window.attachEvent && window.attachEvent("onload",setupSlider)

const mouseOutThrottle = () => {
    // if slidestate is still falsey no new menu element is hovered
    if ( !slideState ) {
        menuSlider.style.width = '0'
        menuSlider.classList.remove('sliding')
    }
}

function navSlider(callback) {
    
    // we only want the <li> tags
    const els = document.querySelectorAll('.menu-item')

    if (els.length > 0){
        const marginLeft = []
        els.forEach((el, index) => {
            // the total width of the element
            let width = el.offsetWidth
            //console.log('offsetWidth: ' + width)
            //let test = el.getBoundingClientRect().width
            //console.log('boundingRect: ' + test)
            // SOWOHL offsetWidth, als auch clientWidth, als auch getBoundingClientRect().width geben bei einem Refresh falsche Werte
            
            
            // the width - padding left/right
            let innerWidth = width - (padding * 2) // 
            let tMarginLeft = 0

            if (index != 0) {
                // all elements except the first
                tMarginLeft = getArraySum(marginLeft) + (padding - 2)
            } else {
                // the first element
                tMarginLeft = (padding - 2)
            }
            callback(el, innerWidth, tMarginLeft)
            marginLeft.push(width)
        })
    }
}
/**
 * 
 * Helper method to calculate the left margin of the underline
 */
function getArraySum( arr ) {
    let sum = 0
    arr.forEach((el) => {
        sum += el
    })
    return sum
}