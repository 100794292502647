/**
 * IMPORTS
 */
import './assets/js/prefix'
import './assets/js/cookies'
import './assets/js/nav-slider'
import './assets/js/burger'
import './assets/js/scroll-header'
import './assets/js/lang-switch'
//import './assets/js/sticky-tab'
import './assets/js/active-page'
import './assets/js/accordion'
import './assets/js/layer-reveal-2'
import './assets/js/layer-reveal-solo'
import './assets/js/image-grid-12' // 9 for regular version (old)
//import './assets/js/flexlay-collapsible'
import './assets/js/animate-2'
import './assets/js/countup'
import './assets/js/slideshow-2'