/**
 * Adds a class to the menu element of the curently active page
 */
// we only want the <a> tags inside the menu <li>
const els = document.querySelectorAll('.menu-item a')
// get the page id attribute (data-pageid) from the <html> element and convert to integer
const activePage = parseInt(document.documentElement.dataset.pageid)
// subpages can define a menuId in their html element
const menuId = document.documentElement.dataset.menuid ? parseInt(document.documentElement.dataset.menuid) : undefined


if (els.length > 0 && activePage > 0){
    els.forEach((el, index) => {
        el.classList.remove('active')
    })
    // if an extra menuId is not defined by the page (for sublevel pages), the activePage is used (for top level pages)
    if ( ! menuId ) {
        if((activePage) <= els.length ) {
            els[(activePage - 1)].classList.add('active')
        }
    } else {
        els[(menuId - 1)].classList.add('active')
    }
}