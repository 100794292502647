/**
 * This module gets the vendor prefix for the browser. 
 * Javascript functions that manipulate css attributes can apply for returning the correct - possibly prefixed – css name
 * A script should only ask once and save the result for further processing
 */
/*-------------------------------------------------------------------------------
# Get Vendor Prefix
-------------------------------------------------------------------------------*/
const prefix = (function getPrefix () {
    
        const ua = navigator.userAgent.toLowerCase(),
            match = /opera/.exec(ua) || /msie/.exec(ua) || /firefox/.exec(ua) || /(chrome|safari)/.exec(ua),
            vendors = {
                opera: 'O',
                chrome: 'webkit',
                safari: 'webkit',
                firefox: 'Moz',
                msie: 'ms'
            };
        
        return vendors[match[0]];
})()

const blindDiv = document.createElement('div')
/*-------------------------------------------------------------------------------
# Get Style Name (Public function)
-------------------------------------------------------------------------------*/
function getStyleName(s) {
    //
    if (s && typeof s === 'string' ) {
        
        //
        const styleName = s;
        
        // No need for prefix
        if (styleName in blindDiv.style) {
            return styleName;
        }
        
        // Create style with vendor prefix
        const prefixStyle = prefix + styleName.slice(0,1).toUpperCase() + styleName.slice(1);

        // Check again
        if (prefixStyle in blindDiv.style) {
            return prefixStyle;
        }

        // Browser has no support for this style.
        return false;
        
    }
}
/*-------------------------------------------------------------------------------
# Module Export
-------------------------------------------------------------------------------*/
export default getStyleName;

/**
 * Usage:
 * import getStyleName from './prefix'
 * const styleName = getStyleName('unprefixed Stylename')
 */