/*-------------------------------------------------------------------------------
# Smooth Scroll Polyfill
-------------------------------------------------------------------------------*/
/**
 * Import smooth-scroll from c.ferdinandi (more control of scrolling than with native CSS smooth scroll)
 * from https://github.com/cferdinandi/smooth-scroll
 */
import SmoothScroll from 'smooth-scroll'
const scroll = new SmoothScroll('a[href*="#"]',{
    ignore: '[data-scroll-ignore]', // Selector for links to ignore (must be a valid css-selector)
    header: null, // Selector for fixed header (must be valid css)
    topOnEmptyHash: false, // true = scroll to top of page with href="#""
    speed: 1000, // amount of time in millis to scroll 1000px
    clip: true, // adjust scroll distance to prevent abrupt stops near bottom of the page
    easing: 'easeInOutCubic',
    updateURL: false
})
/*-------------------------------------------------------------------------------
# Generic Replace Class
-------------------------------------------------------------------------------*/
/**
 * @param {*} elem the element of which the classList should be altered
 * @param {*} add add a class to the classList (use 'undefined' if unused)
 * @param {*} rmv remove a class of the classList (use 'undefined' if unused)
 */
const replaceClass = ( elem, add, rmv ) => {
    if ( add ) {
        elem.classList.add( add );
    }
    if ( rmv ) {
        elem.classList.remove( rmv );
    }
}

/*-------------------------------------------------------------------------------
# Generic Toggle Class
-------------------------------------------------------------------------------*/
/**
 * @param {*} elem the element of which the classList should be altered
 * @param {*} t add or remove a class to the classList
 */
const toggleClass = ( elem, t ) => {
    if ( elem && t ) {
        elem.classList.contains( t ) ? elem.classList.remove( t ) : elem.classList.add( t );
    }
}
/*-------------------------------------------------------------------------------
# Module Export
-------------------------------------------------------------------------------*/
export default {replaceClass, toggleClass}