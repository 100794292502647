/* ==============================================================================
 * Image Grid JS - v12
 * ==============================================================================
 * Check dataset for missing images (works only at the end of dataset)
 */
/* ------------------------------------------------------------------------------
 * Vars
 * ------------------------------------------------------------------------------
 */
import data from '../data/lawyers.json'
import images from '../img/team2/*.jpg' // the only way to make it work with Parcel to use dynamic images
let numImages = data.length
const minImages = 6 // define the minimum number of images
const maxImages = 13 // define the maximum number of images
const mobileBP_2 = 569 // matches "s" breakpoint from CSS (trigger for 80% strip)
const mobileBP = 859 // matches "smm" special breakpoint from CSS (trigger for strip)
const tabletBP = 1179 // matches "ml" breakpoint from CSS (trigger for alternative layout)
const imgGridCon = document.querySelector( '.image-grid' )
// specify an imageset to use, either "city" or "portrait"
const imageset = imgGridCon && imgGridCon.dataset.imageset ? imgGridCon.dataset.imageset : 'portrait'
const imgMargin = 16
const imgLarger = 1.25
const delay = .1

let theMatrix = []
let cons = []
const firstRowCons = []
const secondRowCons = []
const thirdRowCons = []

let oldBaseWidth = 0
let hasThirdRow = false
// check if there’s a data-tablet-alt-lay attribute set to "true" (everything else falls back to false)
const hasTabletLay = imgGridCon && imgGridCon.dataset.tabletAltLay && imgGridCon.dataset.tabletAltLay === "true" ? true : false
// false = order like order on JSON source | true = random order changes on each page reload
const randomize = imgGridCon && imgGridCon.dataset.random && imgGridCon.dataset.random === "true" ? true : false
const randomizeOnMobile = false // random also for mobile
let isMobile = false
let isTablet = false
//let isSwipe = false
let ranData = []
// an invisible callback Element, that fires when the enlarge/diminish anim is finished (triggered after last element completes)
let callbackCon

// New Stuff
const scaleMode = "scale" // either "scale" or "lock"
const scaleFactor = 1.2 // scale-factor that is always applied when checking for right image size to choose
const imgSizes = [240,320,400,480,560,640,720,800,960,1120]

// Language related

// get the page language from the <html> element set a flag either to "_en" or empty ""
const pageLang = document.documentElement.lang
const langFlag = (pageLang !== "en") ? "" : "_en"

/* ------------------------------------------------------------------------------
 * Check images in dataset
 * ------------------------------------------------------------------------------
 */
if ( imgGridCon ) {
    const tField = ( imageset === 'city' ) ? 'img' : 'face';
    // check if a field is empty (no image defined) and correct total number of images accordingly
    data.forEach( ( entry ) => {
        if ( entry[tField] === '' ) {
            numImages--;
        }
    })
}
/* ------------------------------------------------------------------------------
 * Helpers
 * ------------------------------------------------------------------------------
 */
// Device Pixel Ratio check
const DPR = () => {
    let tVal = window.devicePixelRatio;
    tVal = (tVal > 2) ? 2 : tVal
    tVal = (tVal < 1 || tVal == undefined || tVal == null) ? 1 : tVal
    return tVal
}

// Sum of all array numerical values, nice!
const arrSum = arr => arr.reduce((a,b) => a + b, 0)
// the maximum available width (viewport - 2 times body margin)
const maxWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - (2 * imgMargin)
// the maximum number of images in the two rows (if uneven bottom row is +1 – currently)
const bottomCount = () => Math.ceil((numImages - 1) / 2)
const topCount = () => numImages - 1 - bottomCount() // currently unused

const vx = 1            // vertical, portrait image
const vy = 1.5
const hx = 1.5          // horizontal, landscape image
const hy = 1
const sx = 1            // square image
const sy = 1
const lx = imgLarger    // large square
const ly = imgLarger
const xx = 1/imgLarger  // small portrait image
const xy = 1

/* ------------------------------------------------------------------------------
 * Randomizer
 * ------------------------------------------------------------------------------
 * Randomizes the order on the array by using a Fisher-Yates Algorithm
 */
const randomizeArray = (arr) => {
    if (arr) {
        //for ( let i = arr.length - 1; i > 0; i-- ) {
            // slightly modified as we check for missing images in one or the other set at the end of the lawyers.json data
            for ( let i = numImages - 1; i > 0; i-- ) {
            const j = Math.floor( Math.random() * ( i + 1 ) )
            const t_elem = arr[i]
            arr[i] = arr[j]
            arr[j] = t_elem
        }
        return arr
    }
}
/* ------------------------------------------------------------------------------
 * Set Event Handlers
 * ------------------------------------------------------------------------------
 * Use CSS solution now, no javascript touch stuff necessary (hopefully)
 */
const setEventHandlers = (con, id) => {
    // Event Handlers on image
    const t_con = con
    const t_id = id
    //
    t_con.addEventListener('click', function _click(e) {
        e.preventDefault()
        document.location = "./" + ranData[t_id].page + langFlag + ".html"
    }, true )
}
/* ------------------------------------------------------------------------------
 * The Matrix
 * ------------------------------------------------------------------------------
 * Now featuring a third row and alternative layouts for tablet
 */ 
const doTheMatrix = (n) => {
    // limit the possible number to maxImages
    if ( n > maxImages ) { n = maxImages }
    // recalculate with minImages to get the right matrix from the master array (that starts at pos 0 with the matrix for minImages)
    const num = n - minImages
    // The Master Matrix
    // the second row contains the first (single, offset) image
    const masterMatrix = [
        // 0 (minImages, currently for 6 images)
        [   
            [   // 0 (desktop layout)
                [     lx, hx     ], // Row 1 (top x)
                [     ly, hy     ], // Row 1 (top y)
                [ vx, hx, vx, sx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy ], // Row 2 (bottom y)
            ],
            [   // 1 (tablet layout)
                /*[     lx, sx, hx ], // Row 1 (top x)
                [     ly, sy, hy ], // Row 1 (top y)
                [ vx, hx, sx     ], // Row 2 (bottom x)
                [ vy, hy, sy     ], // Row 2 (bottom y)*/
                [     lx, hx     ], // Row 1 (top x)
                [     ly, hy     ], // Row 1 (top y)
                [ vx, hx, vx, sx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy ], // Row 2 (bottom y)
            ],
        ],
        // 1 (currently for 7 images)
        [   
            [   // 0 (desktop layout)
                [     lx, sx, hx ], // Row 1 (top x)
                [     ly, sy, hy ], // Row 1 (top y)
                [ vx, hx, vx, sx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy ], // Row 2 (bottom x)
            ],
            [
                // 1 (tablet layout)
                [     lx, sx, hx ], // Row 1 (top x)
                [     ly, sy, hy ], // Row 1 (top y)
                [ vx, hx, vx, sx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy ], // Row 2 (bottom x)
            ],
        ],
        // 2 (currently for 8 images)
        [   
            [   // 0 (desktop layout)
                [     lx, sx, hx     ], // Row 1 (top x)
                [     ly, sy, hy     ], // Row 1 (top y)
                [ vx, hx, vx, sx, xx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy, xy ],  // Row 2 (bottom y)
            ],
            [
                // 1 (tablet layout)
                [     lx, sx, hx     ], // Row 1 (top x)
                [     ly, sy, hy     ], // Row 1 (top y)
                [ vx, hx, vx, sx, xx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy, xy ],  // Row 2 (bottom y)
            ],
        ],
        // 3 (currently for 9 images) - maybe the first block to use 3 rows?
        [   
            /*[   // 0 (desktop layout)
                [     lx, sx, hx, sx ], // Row 1 (top x)
                [     ly, sy, hy, sy ], // Row 1 (top y)
                [ vx, hx, vx, sx, xx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy, xy ], // Row 2 (bottom y)
            ],*/
            [   // 0 (desktop layout)
                [     lx, hx, sx     ], // Row 1 (top x)
                [     ly, hy, sy     ], // Row 1 (top y)
                [ vx, vx, sx, hx, sx ], // Row 2 (bottom x)
                [ vy, vy, sy, hy, sy ], // Row 2 (bottom y)
                [     0,  hx         ], // Row 3 (bottom x) - one image, first position empty
                [     0,  hy         ], // Row 3 (bottom y) - one image, first position empty
            ],
            [
                // 1 (tablet layout)
                [     lx, sx, hx     ], // Row 1 (top x)
                [     ly, sy, hy     ], // Row 1 (top y)
                [ vx, hx, sx, sx     ], // Row 2 (bottom x)
                [ vy, hy, sy, sy     ], // Row 2 (bottom y)
                [     sx, vx         ], // Row 3 (bottom x)
                [     sy, vy         ], // Row 3 (bottom y)
            ]
        ],
        // 4 (currently for 10 images, v1)
        [   
            [   // 0 (desktop layout)
                [     lx, hx, sx, sx     ], // Row 1 (top x)
                [     ly, hy, sy, sy     ], // Row 1 (top y)
                [ vx, vx, sx, hx, sx     ], // Row 2 (bottom x)
                [ vy, vy, sy, hy, sy     ], // Row 2 (bottom y)
                [     0,  hx             ], // Row 3 (bottom x) - one image, first position empty
                [     0,  hy             ], // Row 3 (bottom y) - one image, first position empty
            ],
            [
                // 1 (tablet layout)
                [     lx, sx, hx     ], // Row 1 (top x)
                [     ly, sy, hy     ], // Row 1 (top y)
                [ vx, hx, sx, sx     ], // Row 2 (bottom x)
                [ vy, hy, sy, sy     ], // Row 2 (bottom y)
                [     sx, vx, lx     ], // Row 3 (bottom x) - one image, first position empty
                [     sy, vy, ly     ], // Row 3 (bottom y) - one image, first position empty
            ]
        ],
         // 5 (currently for 11 images) - not updated for 3 rows yet
        [   
            [   // 0 (desktop layout)
                [     lx, sx, hx, sx, xx ], // Row 1 (top x)
                [     ly, sy, hy, sy, xy ], // Row 1 (top y)
                [ vx, hx, vx, sx, xx, sx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy, xy, sy ], // Row 2 (bottom y)
            ],
            [
                // 1 (tablet layout)
            ]
        ],
         // 6 (currently for 12 images) - not updated for 3 rows yet
        [   
            [   // 0 (desktop layout)
                [     lx, sx, hx, sx, sx     ], // Row 1 (top x)
                [     ly, sy, hy, sy, sy     ], // Row 1 (top y)
                [ vx, hx, vx, sx, hx, xx, sx ], // Row 2 (bottom x)
                [ vy, hy, vy, sy, hy, xy, sy ], // Row 2 (bottom y)
            ],
            [
                // 1 (tablet layout)
            ]
        ],
    ]
    // return the matching matrix
    return masterMatrix[num]
}
/* ------------------------------------------------------------------------------
 * Image Loader (NEW)
 * ------------------------------------------------------------------------------
 *  
 */
const imageLoader = (d, i, s) => {
    const imgCon = d
    const index = i
    const size = s
    // This needs to be much smarter...
    const goal = Math.abs((size * scaleFactor) * DPR())
    //const nearestSize = imgSizes.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
    let nearestSize = imgSizes.find(function(el) {return el > goal})
    if(nearestSize === undefined){ nearestSize = imgSizes[imgSizes.length - 1]}
    //console.log("goal: " + goal)
    //console.log("nearestSize: " + nearestSize)
    const sizeFlag = '-' + nearestSize
    
    // Here I need to implement a suffix to the image name in relation to the pattern
    let t_imgName
    // check which imageset to use
    if ( imageset === 'city' ) {
        t_imgName = ranData[index].img + sizeFlag
    } else {
        t_imgName = ranData[index].face + sizeFlag
    }
    
    imgCon.style.backgroundImage = `url(${images[t_imgName]})`
        //imgDiv.src = "./" + images[t_imgName]
}
/* ------------------------------------------------------------------------------
 * Setup Grid
 * ------------------------------------------------------------------------------
 * Only called once - but for desktop and smartphone alike
 */
const setupGrid = () => {

    // get the matching matrix for the current number of images
    theMatrix = doTheMatrix(numImages)

    // check if the desktop matrix contains a third row (if larger than 4 entries or equals 6 entries)
    if ( theMatrix[0].length == 6 ) { hasThirdRow = true }
    
    // Here I could also check if the matrix has an ALTERNATIVE LAYOUT (i.e. for tablet)
    // --> The matrix needs another depth level then
    //
    // we could also use a "seed" here to have the array show in original order in n cases of the request
    // I have the feeling that the algorithm never uses the original order
    // RANDOM --> maybe move away from here
    if ( randomize == true ) {
        ranData = randomizeArray(data)
    } else {
        ranData = [...data]
    }
    // perform an initial mobile and tablet breakpoint check and set a bool accordingly
    // CURRENTLY UNUSED
    const t_mBP = mobileBP - ( 2 * imgMargin )
    const t_tBP = tabletBP - ( 2 * imgMargin )
    if ( maxWidth() < t_tBP && maxWidth >= t_mBP ) {
        // tablet range
       isTablet = true
       isMobile = false
    } else if ( maxWidth() < t_mBP ) {
        // desktop range
        isTablet = false
        isMobile = true
    } else {
        // desktop range
       isTablet = false
       isMobile = false
    }
    
    // Create Container Divs and add to DOM
    let fragment = document.createDocumentFragment() // create a fragment container to insert all images at once
    for (let i = 0; i < numImages; i++) {
        let conDiv = document.createElement('div') // the container
        let animDiv = document.createElement('div') // the inner anim div (wrapping the image div)
        let imgDiv = document.createElement('div') // the inner div (for images)
        //let imgDiv = document.createElement('img') 
        let nameDiv = document.createElement('div') // the div for the name hover
        let nameTxt = document.createElement('h4') // the name itself
        let ctaTxt = document.createElement('a')

        conDiv.classList.add('image-grid_con')
        // we add a .hover class to the container and apply the :hover only if this class is present
        conDiv.classList.add('hover')
        animDiv.classList.add('image-grid_con__anim')
        imgDiv.classList.add('image-grid_con__img')
        nameDiv.classList.add('image-grid_con__name')
        if (!isMobile){
            nameDiv.classList.add('light-theme')
        }
        nameTxt.innerText = ranData[i].name
        ctaTxt.href = "./" + ranData[i].page + langFlag + ".html"
        // We have to implement language checking
        if (langFlag === "_en") {
            ctaTxt.title = "Find out more about " + ranData[i].name
            ctaTxt.innerText = "> find out more"
        } else {
            ctaTxt.title = "Mehr über " + ranData[i].name + " erfahren"
            ctaTxt.innerText = "> mehr erfahren"
        }
        

        // attach the Event Handlers
        setEventHandlers(conDiv, i)
        
        //imageLoader(imgDiv, i, 200)

        nameDiv.appendChild(nameTxt)
        nameDiv.appendChild(ctaTxt)
        animDiv.appendChild(imgDiv)
        conDiv.appendChild(animDiv)
        conDiv.appendChild(nameDiv)
        fragment.appendChild(conDiv)
    }
    // finally adding the blind element
    let callbackElem = document.createElement('div')
    callbackElem.classList.add('image-grid_animTracker')
    callbackElem.style.transitionDelay = ((numImages) * delay) + "s"
    fragment.appendChild(callbackElem)
    //
    imgGridCon.appendChild(fragment)
    //
    callbackCon = imgGridCon.querySelector('.image-grid_animTracker')
    
    //callbackCon.addEventListener('transitionend', animEndCallback, false)
}
if ( imgGridCon ) {
    setupGrid()
}
/* ------------------------------------------------------------------------------
 * Calculate Base Width (of Grid)
 * ------------------------------------------------------------------------------
 *  Calculation of Grid Base Width (for x-val = 1) derived from the widest row
 *  Should work with optionally 3 rows as well 
 *  The first "solo" image is always the first entry of the second row (array index = 2)
 */
const calcBaseWidth = (mx) => {
       
        const t_mx = mx
        const tmpSumArr = []
        const firstElemX = theMatrix[t_mx][2][0] // x-val of the first "solo" image
        //
        theMatrix[t_mx].forEach((elem, index) => {
            // we only need even elems (with x-vals)
            if ( index % 2 == 0 ) {
                // calculate the sum of the x-vals
                let t_arrSum = arrSum(elem)
                // add the x-val of the first image – if it’s not the bottom row (which already contains it)
                if ( index != 2 ) {
                    t_arrSum += firstElemX
                }
                tmpSumArr.push(t_arrSum)
            }
        })
        // find the larger value in the array
        const tMaxX = Math.max(...tmpSumArr)
        // find the index of the largest element
        const tWhichIsMax = tmpSumArr.indexOf(tMaxX)
        // strangely if the first row is the longest (tablet layout i.e.) we have set this to zero
        // this is hacky but seems to work, before the alternative layout solution corrFactor was always 1
        let corrFactor = 1
        if (tWhichIsMax === 0) {corrFactor = 0}
        // use this index to get the length of the matrix array
        const tLength = theMatrix[t_mx][tWhichIsMax * 2].length
        // calculate the base width
        const tBaseWidth = Math.round((maxWidth() - ((tLength - corrFactor) * imgMargin)) / tMaxX)
        // return it
        return tBaseWidth
}
/* ------------------------------------------------------------------------------
 * Calculate Grid
 * ------------------------------------------------------------------------------
 * Called initially and on each resize (Desktop View)
 * Not yet updated to work with optionally 3 rows
 */
const calcGrid = (fR) => {

    if (imgGridCon) {
        const t_firstRender = fR
        // Reset (= [], doesn’t work as array is const)
        firstRowCons.length = 0
        secondRowCons.length = 0
        thirdRowCons.length = 0
        cons.length = 0
        
        // Check if desktop or tablet breakpoint (and potentially alternative desktop layouts)
        // and check if a second layout exists at all, otherwise always use the desktop layout
        const t_maxWidth = maxWidth() + (2 * imgMargin)
        let t_mx = 0
        if (tabletBP && t_maxWidth <= tabletBP) {
            // Tablet Matrix
            t_mx = 1
        } else {
            // Main Desktop Matrix
            t_mx = 0
        }
        // Calculate the base width
        const baseWidth = calcBaseWidth(t_mx)
        //
        let tX = 0
        let tY = 0
        let tWidth = baseWidth
        let tHeight = baseWidth
        let tArr = []
        // need to calculate offset first (of largest top row element)
        let tOffset = Math.floor(Math.max(...theMatrix[t_mx][1]) * baseWidth) - baseWidth
        //···············································································
        // Loop through matrix for second row
        theMatrix[t_mx][2].forEach((elem, index) => {
            // containers in bottom row without the first (single container)
            if( index > 0 ) {
                tX = tX + imgMargin
                tY = 2 * imgMargin + baseWidth + tOffset
            } else {
                // first image
                tX = tX
                tY = 2 * imgMargin + tOffset // y-Offset for first image (same as normal height top row)
            }
            tWidth = Math.floor(elem * baseWidth)
            tWidth = (tWidth % 2 == 0) ? tWidth : tWidth + 1
            tHeight = Math.floor(theMatrix[t_mx][3][index] * baseWidth)
            tHeight = (tHeight % 2 == 0) ? tHeight : tHeight + 1
            tArr = [tX, tY, tWidth, tHeight]
            secondRowCons.push(tArr)
            tX = tX + tWidth // reset for next iteration
        })
        //···············································································
        // reset tX
        tX = theMatrix[t_mx][2][0] * baseWidth
        // Loop through matrix for first row
        theMatrix[t_mx][0].forEach((elem, index) => {
            tWidth = Math.floor(elem * baseWidth)
            tWidth = (tWidth % 2 == 0) ? tWidth : tWidth + 1
            tHeight = Math.floor(theMatrix[t_mx][1][index] * baseWidth) // needs to be improved, covers only square and landscape
            tHeight = (tHeight % 2 == 0) ? tHeight : tHeight + 1
            tX = tX + imgMargin
            let t_topOffset = baseWidth - tHeight + tOffset
            tY = imgMargin + t_topOffset
            tArr = [tX, tY, tWidth, tHeight]
            firstRowCons.push(tArr)
            tX = tX + tWidth // reset for next iteration
        })
        //···············································································
        // If there is a third row, loop through third row
        // tX reset (same as for first row)
        tX = (theMatrix[t_mx][2][0] * baseWidth) //+ imgMargin
        // Loop
        if (hasThirdRow) {
            // a counter/pointer that indicates the starting "slot" for the third row image / images
            theMatrix[t_mx][4].forEach((elem, index) => {
                // check if there are empty slots in the third row and move the pointer accordingly
                if ( elem === 0) { 
                    tX = tX + (secondRowCons[index + 1][2]) + imgMargin
                } else {
                    tWidth = Math.floor(elem * baseWidth)
                    tWidth = (tWidth % 2 == 0) ? tWidth : tWidth + 1
                    tHeight = Math.floor(theMatrix[t_mx][5][index] * baseWidth)
                    tHeight = (tHeight % 2 == 0) ? tHeight : tHeight + 1
                    tY = secondRowCons[index + 1][1] + secondRowCons[index + 1][3] + imgMargin
                     // Sonderfall für die tX Neuberechnung
                     let tRow2SameSlotWidth = secondRowCons[index + 1][2]
                     // Check if the second row has +2 images at all, if not ignore
                     if ( secondRowCons[index + 2] ) {
                        let tRow2NextSlotHeight = secondRowCons[index + 2][3]
                        let tRow2NextSlotX = secondRowCons[index + 2][0]
                        if ( tRow2NextSlotHeight > baseWidth && tRow2SameSlotWidth !== tWidth) {
                            // special case (i.e. only 1 image in third row)
                            // ! only for version with 10 images
                            //tX = tRow2NextSlotX - imgMargin - tWidth
                            // for version with 9 images
                            tX = tX + imgMargin
                        } else {
                            // normal case, multiple images in row 3
                            tX = tX + imgMargin
                        }
                     } else {
                        // normal case, multiple images in row 3
                        tX = tX + imgMargin
                     }
                    tArr = [tX, tY, tWidth, tHeight]
                    thirdRowCons.push(tArr)
                    tX = tX + tWidth // reset for next iteration
                }
            })
        }
        //···············································································
        // Get the max height for the container (new new version)
        const finalRowHeights = []
        // if has a third row use these images
        if (hasThirdRow) {
            thirdRowCons.forEach((elem, index) => {
                let tBottomY = elem[1] + elem[3]
                finalRowHeights.push(tBottomY)
            })
        } else {
            secondRowCons.forEach((elem, index) => {
                let tBottomY = elem[1] + elem[3]
                finalRowHeights.push(tBottomY)
            })
        }
        const tTallest = Math.max(...finalRowHeights)
        const totalHeight = tTallest + imgMargin
        //imgGridCon.style.backgroundColor = "#FF3300" // container background for testing
        imgGridCon.style.height = totalHeight + "px"
        //···············································································
        // Merge the arrays stepwise and create one array
        // let’s for now add the third row at the end
        let tStepwiseCounter = numImages
        let thirdRowLength = thirdRowCons.length
        if ( thirdRowLength > 0 ) {
            tStepwiseCounter = tStepwiseCounter - thirdRowLength
        }
        const tCons = []
        for (let i = 0; i < tStepwiseCounter; i++) {
            if ( i % 2 == 0) {
                let pointer = i / 2
                let t_Arr = secondRowCons[pointer]
                tCons.push(t_Arr)
            } else {
                let pointer = (i-1) / 2
                let t_Arr = firstRowCons[pointer]
                if(t_Arr == undefined){
                    t_Arr = secondRowCons[secondRowCons.length - 1] // last element from other array
                }
                tCons.push(t_Arr)
            }
        }
        if ( thirdRowLength > 0 ) {
            cons = [...tCons, ...thirdRowCons]
        } else {
            cons = [...tCons]
        }
        // Transition-Delay-Stagger-Effect
        let t_Stagger = 0
        const t_lastIndex = cons.length - 1

        if (oldBaseWidth < baseWidth) {
            // Enlarge
            t_Stagger = t_lastIndex
        } else {
            // Diminish
            t_Stagger = 0
        }

        // check if a callback exists for 
        /*if (t_Stagger === 0) {
            // Enlarge animation, first image is the last one animated
            if (callbackCon) {
                // if the callback is set on another image, remove it (if its the same image we can keep the callback)
                if (callbackCon != imgGridCon.children[t_lastIndex]) {
                    callbackCon.removeEventListener('transitionend', animCallback, false)
                    imgGridCon.children[t_lastIndex].addEventListener('transitionend', animCallback, false)
                    callbackCon = imgGridCon.children[t_lastIndex]
                }
            } else {
                // first time set or after change from strip to grid
                imgGridCon.children[t_lastIndex].addEventListener('transitionend', animCallback, false)
                callbackCon = imgGridCon.children[t_lastIndex]
            }
            
        } else {
            // Diminish animation, last image is the last one animated
            if (callbackCon) {
                // if the callback is set on another image, remove it (if its the same image we can keep the callback)
                if (callbackCon != imgGridCon.children[0]) {
                    callbackCon.removeEventListener('transitionend', animCallback, false)
                    imgGridCon.children[0].addEventListener('transitionend', animCallback, false)
                    callbackCon = imgGridCon.children[0]
                }
            } else {
                // first time set or after change from strip to grid
                imgGridCon.children[0].addEventListener('transitionend', animCallback, false)
                callbackCon = imgGridCon.children[0]
            }
        }*/
        //
        cons.forEach((elem, index) => {
            let t_Style = imgGridCon.children[index].style
            t_Style.left = elem[0] + "px"
            t_Style.top = elem[1] + "px"
            t_Style.width = elem[2] + "px"
            t_Style.height = elem[3] + "px"
            t_Style.transitionDelay = (Math.abs(t_Stagger - index) * delay) + "s"
            //
            let t_nameDiv = imgGridCon.children[index].querySelector('.image-grid_con__name')
            t_nameDiv.classList.add('light-theme')
            // 
            let t_imgDiv = imgGridCon.children[index].querySelector('.image-grid_con__img')

            if (elem[2] >= elem[3]) {
                imageLoader(t_imgDiv, index, elem[2])
            } else {
                imageLoader(t_imgDiv, index, elem[3])
            }
            // check if we have a horizontal image and add a special class
            if ( elem[2] > elem[3] ) {
                t_imgDiv.classList.add('is-landscape')
                if ( imageset === 'portrait' ) {
                    t_imgDiv.classList.add('is-face-set')
                }
            }

            // Programmatic sizing
            // At first we need the flexible image loader and a way to save the size value with the image/frame
            if (scaleMode === "lock") {

            } else if (scaleMode === "scale") {
                /*t_imgDiv.style.width = elem[2] + "px";
                t_imgDiv.style.height = elem[2] + "px";*/
            }

            // change the "blind" elements left value (to trigger transition + callback) 
            if (index === t_lastIndex) {
                callbackCon.style.left = elem[0] + "px"
            }
        })
        
        // also set transition delay on grid container height dynamically
        // should behave differently for enlarge and diminish as well
        imgGridCon.style.transitionDelay = (Math.abs(t_Stagger - (cons.length-1)) * delay) + "s"
        
        // The callback on the "blind" for the animation (prevent to add callback more than once)
        if (callbackCon.getAttribute('track-transition-end') !== 'true') {
            callbackCon.addEventListener('transitionend', (e) => {
                // track target again, for possible child elements that transition (not the case here)
                if (e.target === callbackCon) {
                    callbackCon.setAttribute('track-transition-end','true')
                    cons.forEach((elem, index) => {
                        let tmp_nameDiv = imgGridCon.children[index].querySelector('.image-grid_con__name')
         
                        const t_nameTxtHeight = tmp_nameDiv.querySelector('h4').offsetHeight
                        const t_Diff = t_nameTxtHeight - 23
                        const t_nameDivNewHeight = 64 + t_Diff
                        tmp_nameDiv.style.height = t_nameDivNewHeight + 'px'

                        
                    })   
                }
                
            }, true)
        }
        oldBaseWidth = baseWidth
    }
}

/* ------------------------------------------------------------------------------
 * Calculate Strip
 * ------------------------------------------------------------------------------
 * Calculates the image strip layout for mobile views (horizontal slideshow)
 */
const calcStrip = () => {
    if (imgGridCon) {
        // Reset: Even reset the containers only needed for desktop
        secondRowCons.length = 0
        firstRowCons.length = 0
        cons.length = 0
        // Calculate the base width for the slideshow
        const t_maxWidth = maxWidth() + (2 * imgMargin);
        let baseWidth;
        //
        let xtraDist = 0

        if (t_maxWidth > mobileBP_2) {
            baseWidth = Math.floor(maxWidth() * 0.425) //- imgMargin
            xtraDist = 24 // wider strip needs more distance because of larger 2-line names
        } else {
            baseWidth = Math.floor(maxWidth() * 0.8) //- imgMargin
        }
        let tX = 0
        let tY = 0 // was: imgMargin
        let tWidth = baseWidth
        let tHeight = baseWidth * 1 // square ratio
        // distance to scrollbar below
        imgGridCon.style.height = tHeight + 68 + xtraDist + "px"
        //
        let tArr = []
        //
        for (let i = 0; i < numImages; i++ ) {
            tX = i * (tWidth + imgMargin)
            tArr.push(tX)
        }
        // Transition-Delay-Stagger-Effect
        let t_Stagger = 0
        if (oldBaseWidth < baseWidth) {
            // Enlarge
            t_Stagger = numImages
        } else {
            // Diminish
            t_Stagger = 0
        }
        tArr.forEach((elem, index) => {
            let t_Style = imgGridCon.children[index].style
            t_Style.left = elem + "px"
            t_Style.top =  tY + "px"
            t_Style.width = tWidth + "px"
            t_Style.height = tHeight + "px"
            t_Style.transitionDelay = (Math.abs(t_Stagger - index) * delay) + "s"
            //
            let t_nameDiv = imgGridCon.children[index].querySelector('.image-grid_con__name')
            t_nameDiv.classList.remove('dark-theme')
            //
            let t_imgDiv = imgGridCon.children[index].querySelector('.image-grid_con__img')

            // Dynamic Loader
            imageLoader(t_imgDiv, index, tWidth)
        })
        // also set transition delay on grid container height dynamically
        // should behave differently for enlarge and diminish as well
        imgGridCon.style.transitionDelay = (Math.abs(t_Stagger - numImages) * delay) + "s"

        oldBaseWidth = baseWidth
    }
}
/* ------------------------------------------------------------------------------
 * Check Breakpoint
 * ------------------------------------------------------------------------------
 */
const checkBP = (ini) => {
    if ( imgGridCon ) {
        const firstRender = (ini) ? true : false
        const tBP = mobileBP - ( 2 * imgMargin )
        if ( maxWidth() >= tBP ) {
            // larger than breakpoint (tablet + desktop)
            calcGrid(firstRender)
        } else {
            // smaller than breakpoint (smartphone)
            calcStrip()
        }
    }
}
// Do this once intitially
checkBP(true)
/* ------------------------------------------------------------------------------
 * Resize
 * ------------------------------------------------------------------------------
 * Resize with Debounce: "Resize" normally fires all the time as long as window is resized, now we wait until resizing is finished
 */
let resizeID
window.addEventListener('resize', () => {
    clearTimeout(resizeID)
    resizeID = setTimeout(checkBP, 500)
})
/* ------------------------------------------------------------------------------
 * Exports
 * ------------------------------------------------------------------------------
 * We export the containers array for flexlay-collapsible to use the X-Vals
 */
//export {cons}