/* TO-DOs 
--> Progressive Enhancement für IntersectionObserver mit einbauen!
Wenn ein Browser Intersection Observer nicht unterstützt, wird alles "normal" ohne Animationen angezeigt
--> Gleiches gilt für Browser mit deaktiviertem Javascript, dann muss trotzdem alles angezeigt werden...
--> Den Countup-Script als speziellen AnimType hier mit aufnehmen! Er hat aktuell noch seinen eigenen IntersectionObserver
*/
/**
 * Animatables werden über den Observer getriggert, sie beinhalten einen oder mehrere anims, die als Gruppe oder in Abhängigkeit voneinander animiert werden.
 * Neu: Observer können auch selbst anims sein
 * 
 * Options JSON: aktuell können bei fx_anim und fx_stagger ein Konfigurations-Objekt übergeben werden:
 * data-fx='{
 * "delay":"1",
 * "childDelay":".2",
 * "childAnim:":"NameOfAnimFunc", -- das hab’ ich noch nicht eingebaut, könnte kompliziert sein
 * "x":"-100",
 * "y":"0",
 * "dur":".3"
 * }' (Achtung Quotes müssen so gesetzt werden)
 * 
 * 
 */
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
// the elements that get observed by the API
const animatables = document.querySelectorAll('.fx_spy')
//
const hasIO = (document.documentElement.classList.contains('io')) ? true : false
// the child elements of the animatables (the elements that get animated)
const animChilds = []
const animChildRefs = []
// matches "m" breakpoint from CSS (trigger for strip), Animations will be turned off
const nofxBP = 959
const mobileBP = 569
// get screenWidth for breakpoint checking
const screenWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
// Different threshold for desktop and mobile
const thresh = (screenWidth > mobileBP) ? 0.75 : 0.5
//
// Intersection Observer Config Obj
const config = {
    rootMargin: '0% 0%', // negativ value should create inset
    threshold: [thresh]
}
// animate once or repeatedly (global value)
const animRepeat = false
// delay default
const delayDef = 0
// masterDelay default
const masterDelayDef = 0.2
// stagger childDelay default
const staggerDelayDef = 0.1
/*-------------------------------------------------------------------------------
# Hide all
-------------------------------------------------------------------------------*/
// Is done by setting a class (.io) on document.documentElement with inline js
/*-------------------------------------------------------------------------------
# Init function
-------------------------------------------------------------------------------*/
function initAnimate() {
    // if there are animatables (.fx_spy) on the page
    if (hasIO && animatables && animatables.length > 0) {
        // loop through them
        animatables.forEach(elem => {
            // array of elements that contain the .fx_anim class
            const anims = []
            /* check if .fx_spy and .fx_anim is the same object
            otherwise check for .fx_anim child elements
            */
            if ( elem.classList.contains('fx_anim') ) {
               //spy and anim are the same element
                anims.push(elem)
            } else {
                //anim elements are child elements of spy (not necessarily first order childs)
                anims.push(...elem.querySelectorAll('.fx_anim'))
            }
            
            let t_Del = 0
            // loop through anims if any and route to different animation functions
            if (anims && anims.length > 0) {
                // new empty child array
                const t_Anims = []
                //
                anims.forEach(anim => {
                    // new empty array for each child
                    t_A = []
                    // Multiple-Child STAGGER   
                    if (anim.classList.contains('fx_stagger')) {
                        if (anim.children && anim.children.length > 0) {
                            
                            const t_StaggerChilds = []
                            
                            // Get JSON from dataset - if used
                            const t_O = checkOptions(anim)
                            
                            t_Del = 0 // reset t_Del

                            for (let i = 0; i < anim.children.length; i++) {
                                let t_S = []
                                t_S[0] = anim.children[i]
                                t_S[1] = animate
                                t_S[2] = t_Del
                                t_S[3] = t_O.tfStr
                                t_Del += t_O.staggerDel
                                t_StaggerChilds.push(t_S)
                            }

                            t_A[0] = t_StaggerChilds
                            t_A[1] = stagger
                            t_A[2] = t_O.del
                            //t_Delay += 0
                        }
                    /* All the single element functions that use animate function and fx_trigger class
                        like imageReveal, fade-in, grow-x */    
                    } else {
                        
                        // Get JSON from dataset - if used
                        const t_O2 = checkOptions(anim)
                
                        t_A[0] = anim
                        t_A[1] = animate
                        t_A[2] = t_Del + t_O2.del
                        t_A[3] = t_O2.tfStr
                        t_Del += delayDef 
                    }
                    t_Anims.push(t_A)
                })
                animChilds.push(t_Anims)
            }
            // and add to observe
            observer2.observe(elem)
            // duplicate of the original order of the observe array (as this changes during the observation process)
            animChildRefs.push(elem)
        })
    }
}
/*-------------------------------------------------------------------------------
# Check Dataset Options for fx_anim
-------------------------------------------------------------------------------*/
function checkOptions(a) {

    // Get JSON from dataset - if used
    const t_JSON = a.dataset.fx
    const t_O = t_JSON ? JSON.parse(t_JSON) : undefined

    const t_MasterDel = (t_O && t_O.delay) ? parseFloat(t_O.delay): delayDef
    const t_StaggerDel = (t_O && t_O.childDelay) ? parseFloat(t_O.childDelay): staggerDelayDef

    const t_Dist_X = (t_O && t_O.x) ? parseInt(t_O.x): undefined
    const t_Dist_Y = (t_O && t_O.y) ? parseInt(t_O.y): undefined
    const t_Dur = (t_O && t_O.dur) ? parseFloat(t_O.dur): null

    // if transform-options are set construct a transform-string
    let t_tf_Str = ""
    if (t_Dist_X !== undefined || t_Dist_Y !== undefined) {
        const t_X = t_Dist_X ? t_Dist_X : 0
        const t_Y = t_Dist_Y ? t_Dist_Y : 0
        t_tf_Str = `translate(${t_X}px,${t_Y}px)`
    }
    /* If no values are passed from options data-attribute
    - del & staggerDel return defaults
    - transform returns empty string
    - dur returns null
    */
    return {del:t_MasterDel, staggerDel: t_StaggerDel, tfStr: t_tf_Str, dur: t_Dur}
}
/*-------------------------------------------------------------------------------
# Load listener
-------------------------------------------------------------------------------*/
window.addEventListener ? 
window.addEventListener("load",initAnimate, false) 
: 
window.attachEvent && window.attachEvent("onload",initAnimate)

/*let resizeID
window.addEventListener('resize', () => {
    clearTimeout(resizeID)
    resizeID = setTimeout(resizeCount, 500)
})*/
/*-------------------------------------------------------------------------------
# Observe function
-------------------------------------------------------------------------------*/ 
if (hasIO) {
    observer2 = new IntersectionObserver((els) => {
        els.forEach((el) => {
            if ( el.intersectionRatio > 0 ) {
                // Route Animations
                const index = animChildRefs.indexOf(el.target)
                // animChilds and elems have the same top to bottom order
                animChilds[index].forEach((c) => {
                        c[1](c[0],c[2],c[3])
                })
                // Stop observing after checking once (i. e. for lazy loading)
                if (!animRepeat) {
                    observer2.unobserve(el.target)
                }
            } else {
                //console.log('out')
            }
        })
    }, config)
}
/*-------------------------------------------------------------------------------
# Generic Animate
-------------------------------------------------------------------------------*/
function animate(el, d, t) {
    el.classList.add('fx_trigger')
    el.style.animationDelay = d + 's'
    // if transform-string exists and is not empty
    if (t && t!="") {
        el.style.transform = t
    }
    // probably possible to just remove the image animations by sending a fourth value to the animate function
    // like a "useCallback" = true
    //el.addEventListener('animationend', animationCallback)

    /*function animationCallback() {
        console.log('hello')
        el.removeEventListener('animationend', animationCallback)
        //el.style.animation = 'none'
    }*/
}
/*-------------------------------------------------------------------------------
# Stagger – calls animate() on each child element
-------------------------------------------------------------------------------*/
function stagger(els, d) {
    if (els && els.length > 0) {
        els.forEach((el) => {
            //trigger animate, add the masterDelay
            el[1](el[0], el[2] + d, el[3])
        })
    }
}
/* Completely stop observing all elements */
// observer.disconnect()