// Wichtig Polyfill for IntersectionObserver mit einbauen!
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
const observables = document.querySelectorAll('.observable');
const hasIO = (document.documentElement.classList.contains('io')) ? true : false
const config = {
    rootMargin: '-20% 0%', // negativ value should create inset
    //threshold: [0.25]
}
/*-------------------------------------------------------------------------------
# Init function
-------------------------------------------------------------------------------*/
function setupCount() {
    if (hasIO) {
        observables.forEach(elem => {
            // get the original (target) value from HTML
            let tmpVal = elem.innerHTML
            // and set it as an attribute
            // we could set start, end, duration and "once" values already with html attributes in the html...
            elem.setAttribute('val',tmpVal) 
            let tWidth = Math.ceil(elem.getBoundingClientRect().width)
            elem.style.width = tWidth + "px"
            //
            observer.observe(elem)
        })
    }
}
window.addEventListener ? 
window.addEventListener("load",setupCount, false) 
: 
window.attachEvent && window.attachEvent("onload",setupCount)
/*-------------------------------------------------------------------------------
# Resize function
-------------------------------------------------------------------------------*/
function resizeCount() {
    if (hasIO) {
        observables.forEach(elem => {
            // on resize we need to set the width to auto before resetting the width for the count, otherwise the width wouldn’t be recalculated
            elem.style.width = "auto"
            let tWidth = Math.ceil(elem.getBoundingClientRect().width)
            elem.style.width = tWidth + "px"
        })
    }
}
let resizeID
window.addEventListener('resize', () => {
    clearTimeout(resizeID)
    resizeID = setTimeout(resizeCount, 500)
})
/*-------------------------------------------------------------------------------
# Observe function
-------------------------------------------------------------------------------*/
if (hasIO) {
    observer = new IntersectionObserver((elems) => {
        elems.forEach(elem => {
            if ( elem.intersectionRatio > 0 ) {
                let targetVal = elem.target.getAttribute('val')
                animateValue(elem.target, 0, targetVal, 1000)
                // Stop observing after checking once (i. e. for lazy loading)
                observer.unobserve(elem.target)
            } else {
                //console.log('out')
            }
        })
    }, config)
}
/*-------------------------------------------------------------------------------
# Count function
-------------------------------------------------------------------------------*/
function animateValue(obj, start, end, duration) {
    let startTime = null
    const step = ( time ) => {
        if ( !startTime ) startTime = time
        const progress = Math.min((time - startTime) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        if (progress < 1) {
            window.requestAnimationFrame(step)
        }
    }
    window.requestAnimationFrame(step)
}
/* Completely stop observing all elements */
// observer.disconnect()