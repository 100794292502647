/**
 * Custom header functions and animations
 *
 * Color change from i.e dark hero-header to light on-page header not yet implemented
 */
import base from './basefunctions'
import emitter from './scroll-emitter'
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
const header = document.querySelector( '#masthead .site-header-moveable' )
let headerHeight = header.offsetHeight
const headerTransp = header.classList.contains( 'bg-transp' ) // check if the header has the transparent class assigned (true/false)
const menu = document.querySelector( '#site-navigation' )
let heroHeight = window.innerHeight || document.body.clientHeight // As the header is 100vh
let scrollPos = 0
let scrollDir = false // false = UP / true = DOWN
let onceVisible = false // Bool to check if the Menu has once been slided out

/*-------------------------------------------------------------------------------
# Simple Scroll Checker
-------------------------------------------------------------------------------*/
const checkScrollPos = () => {
	let wY = window.pageYOffset; // better cross-browser compatibility than: let wY = window.scrollY;
    if ( wY > heroHeight ) { // check if we scrolled beyond the hero
        
        // add the fixed class to the menu
        if ( menu ) {
            if( ! menu.classList.contains('on-site-fixed')){
                base.replaceClass( menu, 'on-site-fixed', undefined );
            }
        }
        //
		if ( wY > scrollPos ) { // Check the direction by comparing old and new Y value
			if ( ! scrollDir ) { // Scrolling Down – triggered once
                if ( onceVisible ) { // only execute the hide if the menu was visible at least once
                    base.replaceClass( header, 'header-hidden', 'header-visible' );
				}
			}
			scrollDir = true;
		} else {
            if ( scrollDir ) { // Scrolling UP – triggered once
                if ( headerTransp ) {
                    base.replaceClass( header, undefined, 'bg-transp' ); // remove transparent class if it has been set initially
                }
                base.replaceClass( header, 'header-visible', 'header-hidden' );
				if ( ! onceVisible ) {
					onceVisible = true;
				}
			}
			scrollDir = false;
		}
		scrollPos = wY;
	} else {

		// hide again if inside the hero
		// --> MAYBE THRESHOLD HELPS TO AVOID "FLICKERING"
		if ( wY < ( heroHeight - headerHeight ) && onceVisible ) { // only execute the hide if the menu was visible at least once
            base.replaceClass( header, 'header-hidden', 'header-visible' );
			onceVisible = false;
		}
		if ( wY < headerHeight * 2 ) {
            if ( headerTransp ) {
                base.replaceClass( header, 'bg-transp', undefined ); // add the transparent class if it has been set initially
            }
            base.replaceClass( header, undefined, 'header-hidden' );
            // remove the fixed class of the menu
            menu && base.replaceClass( menu, undefined, 'on-site-fixed' );
		}
	}
}
emitter.on(checkScrollPos)
/*-------------------------------------------------------------------------------
# Debounce Throttle for scroll listener (10 ms)
-------------------------------------------------------------------------------*/
/*const debounce = ( func, wait = 10, immediate = true ) => {
	let timeout;
	return function() {
		let ctx = this,
			args = arguments;
		let later = function() {
			timeout = null;
			if ( ! immediate ) {
				func.apply( ctx, args );
			}
		};
		let callNow = immediate && ! timeout;
		clearTimeout( timeout );
		timeout = setTimeout( later, wait );
		if ( callNow ) {
			func.apply ( ctx, args );
		}
	};
}
window.addEventListener( 'scroll', debounce( checkScrollPos ) );*/