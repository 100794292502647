/**
 * Menu Burger JS
 *
 */
import base from './basefunctions'
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
// const body = document.body; // brauche ich für den Mobile Freeze
const menu = document.querySelector( '#site-navigation' );
const burger = document.querySelector( '.menu-burger' );
let mobileMenuVisible = false; // Bool to indicate if the mobileMenu is visible

/*-------------------------------------------------------------------------------
# Burger Menu
-------------------------------------------------------------------------------*/
burger && burger.addEventListener( 'click', function( e ) {
    e.preventDefault();
    toggleBurgerMenu();
});
/*-------------------------------------------------------------------------------
# Toggle Burger
-------------------------------------------------------------------------------*/
const toggleBurgerMenu = () => {
    // burger to X is now solved with css animations, therefore a simple toggle doesnt work
    if ( mobileMenuVisible ) {
        // closing animation (from X to burger)
        burger.classList.add( 'deactivate' );
        burger.addEventListener( 'animationstart' , startCB1 );
        burger.addEventListener( 'animationend' , endCB1 );
    } else {
        // opening animation (from burger to X)
        burger.classList.add( 'activate' );
        burger.addEventListener( 'animationend' , endCB2 );
    }
    base.toggleClass( menu, 'toggled' ); // show the menu
    mobileMenuVisible = ! mobileMenuVisible;
    //freezeBody();
}
const startCB1 = () => {
    burger.removeEventListener( 'animationstart', startCB1 )
    burger.classList.remove( 'is-active' )
}
const endCB1 = () => {
    burger.removeEventListener( 'animationend', endCB1 )
    burger.classList.remove( 'deactivate' )
}
const endCB2 = () => {
    burger.removeEventListener( 'animationend', endCB2 )
    burger.classList.add( 'is-active' )
    burger.classList.remove( 'activate' )
}

// DAS TUT NOCH NICHT
const freezeBody = () => {
    base.toggleClass( body, 'freeze');
    if ( mobileMenuVisible ) {
        /*header.style.top = '';
        menu.style.top = `${headerHeight}px`;*/
        //body.style.top = `-${scrollPos}px`;
    } else {
        //body.style.top = '';
    }
}

/*-------------------------------------------------------------------------------
# Mobile Menu verbergen, wenn ein Element geklickt wurde
-------------------------------------------------------------------------------*/
/*menuItems.forEach( elem => elem.addEventListener( 'click', function() {

    if ( 'none' != window.getComputedStyle( burger ).display ) {
        toggleBurgerMenu();
    }
    if ( smallMenuActive == true ) {
        hideSmallHeader();
        smallMenuActive = false;
        smallMenuWasActive = true;
    }
}) );*/