//slideshow
/* Improvements: 
1. Indicators should/could be automatically created according to number of slides
2. Hover stop should belong to whole blue section (2 parents up from slideshow or #quote-teaser)
3. Active Element could be "globally" saved and updated, no need to "find" it all the time an event occurs...
4. Transform to class to be usable multiple times on the page
5. With visible Arrows: arrows should
*/
/*-------------------------------------------------------------------------------
# Vars
-------------------------------------------------------------------------------*/
const slideshow = document.querySelector('.slideshow')
const slides = document.querySelectorAll('.slideshow .slide')
const indicators = document.querySelectorAll('.indicators .indicator')
const btns = document.querySelectorAll( '.slideshow-arrows .arrow' );
const prevBtn = document.querySelector( '.arrow-prev' );
const nextBtn = document.querySelector( '.arrow-next' );
/* This could be smarter like .parent.parent or climb up tree and matches(section) */
const parentSection = document.querySelector('#quote-teaser')
const autoplayInt = (slideshow) ? parseInt(slideshow.dataset.autoplayInt) || 4000 : 4000
const active = "active"
const visible = "visible"
let numSlides
let intervalID
/*-------------------------------------------------------------------------------
# Init function
-------------------------------------------------------------------------------*/
function initSlideshow() {
    if (slideshow) {
        if (btns) {
            for ( const btn of btns ) {
                btn.addEventListener( 'click', ( e ) => changeSlide( e ) );
            }
        }
        if (indicators) {
            indicators.forEach((indy, index) => {
                indy.addEventListener( 'click', () => changeSlideWithIndys( index ) );
            })
        }
        numSlides = slides.length
        //changeSlide()
        autoPlay()
        stopAutoplayOnHover()
    }
}
window.addEventListener ? 
window.addEventListener("load",initSlideshow, false) 
: 
window.attachEvent && window.attachEvent("onload",initSlideshow)
/*-------------------------------------------------------------------------------
# Change Slide
-------------------------------------------------------------------------------*/
function changeSlide(e) {
    // get the active slide
    const activeSlide = document.querySelector('.slide.active')
    activeSlide.classList.remove( active )
    // Same animation principle for the indicators
    const activeIndicator = document.querySelector('.indicator.active')
    activeIndicator.classList.remove(active)
    
    if ( e.currentTarget === nextBtn ) {
        // fade out the arrow
        nextBtn.children[0].classList.add('hide')
        activeSlide.nextElementSibling ? activeSlide.nextElementSibling.classList.add( active ) : slides[0].classList.add( active );
        activeIndicator.nextElementSibling ? activeIndicator.nextElementSibling.classList.add( active ) : indicators[0].classList.add( active )
        // fade arrow back in
        setTimeout(function() { nextBtn.children[0].classList.remove('hide') }, 1050)
    } else if ( e.currentTarget === prevBtn ) {
        // fade out arrow
        prevBtn.children[0].classList.add('hide')
        activeSlide.previousElementSibling ? activeSlide.previousElementSibling.classList.add( active ) : slides[numSlides - 1].classList.add( active );
        activeIndicator.previousElementSibling ? activeIndicator.previousElementSibling.classList.add( active ) : indicators[numSlides - 1].classList.add( active )
        // fade arrow back in
        setTimeout(function() { prevBtn.children[0].classList.remove('hide') }, 1050)
    }
}
/*-------------------------------------------------------------------------------
# Change Slide With Indicators
-------------------------------------------------------------------------------*/
function changeSlideWithIndys(index) {
    const activeSlide = document.querySelector('.slide.active')
    // do nothing if we clicked the active slide
    if (activeSlide !== slides[index]) {
        activeSlide.classList.remove( active )
        const activeIndicator = document.querySelector('.indicator.active')
        activeIndicator.classList.remove(active)

        slides[index].classList.add( active )
        indicators[index].classList.add( active )
    }
}
/*-------------------------------------------------------------------------------
# Autoplay function
-------------------------------------------------------------------------------*/
function autoPlay() {
    if (slideshow.dataset.autoplay === "true") {
        intervalID = setInterval(() => {
            nextBtn.click();
        }, autoplayInt)
    }
}
/*-------------------------------------------------------------------------------
# Stop Autoplay
-------------------------------------------------------------------------------*/
function stopAutoplayOnHover() {
    if (
        slideshow.dataset.autoplay === "true" &&
        slideshow.dataset.stopAutoplayOnHover === "true"
    ) {
        parentSection.addEventListener("mouseenter", () => {
            clearInterval(intervalID)
        })
        parentSection.addEventListener("mouseleave", () => {
            autoPlay()
        })
        // touch devies
        parentSection.addEventListener("touchstart", () => {
            clearInterval(intervalID)
        })
        // touch devies
        parentSection.addEventListener("touchend", () => {
            autoPlay()
        })
    }
}